function RealEstateLaw(){
    return(
        <>
<div>
  <section style={{background: 'url("images/inner-realestatelaw-bg.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Real Estate Law</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Buying or selling a home, apartment or commercial property may be the biggest single transaction of your lifetime. Your Life’s Savings, Personal Goals and Needs may be invested in this significant venture. </p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/real_estate_purchase_sale_icons.png" alt="real estate purchase sale icons" />
      <h3 className="h3 m-4 text-blue fw-400">PURCHASE &amp; SALE AGREEMENT</h3>
      <p>Buying or selling a home, apartment or commercial property may be the biggest single transaction of your lifetime. Commonly referred to as “the contract,” few people realize that the Purchase &amp; Sale Agreement document is the most important step in a real estate transaction. The details of this agreement finalize the terms of your purchase or sale. The law requires that You are treated as if you have read and understand each word of the contract before you sign it. The best time to retain an Attorney is <strong className="italic">always before the contract is signed.</strong></p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/real_estate_property_closing_icons.png" alt="real estate property closing icons" />
      <h3 className="h3 m-4 text-blue fw-400">PROPERTY CLOSINGS</h3>
      <p>Closing a Real Estate Sale is a technical and complex procedure. At Kahn &amp; Kahn this is a Highly Supervised meticulous Process which requires fastidious, meticulous drafting of documents to carry out the actual intent of the parties key to this process.</p>
      <p>Our Process includes:</p>
      <ul>
        <li><strong className="m-2">•</strong>Securing Technical Title Requirements</li>
        <li><strong className="m-2">•</strong>Proper signing and acknowledgement of the documents.</li>
        <li><strong className="m-2">•</strong>Precision Recording and delivery of the documents</li>
        <li><strong className="m-2">•</strong>Information and Explanation Delivery to Client , ensuring Understanding</li>
      </ul>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/landlord_tenant_law_icons.png" alt="real estate purchase sale icons" />
      <h3 className="h3 m-4 text-blue fw-400">REAL ESTATE LAW CLOSER LOOK</h3>
      <p>It is extremely important that one utilize caution throughout this process. For your protection, you should always hire an established real estate lawyer. The lawyer’s training and experience will help you avoid troubles and save you both time and expenses, financial and otherwise. Many people do not realize that in the state of Florida non-attorney operated title insurance companies are permitted to perform real estate transaction closings for buyers. Please be forewarned that non-attorney operated title insurance companies cannot represent you nor provide any legal advice. In the event a legal problem arises during your transaction, you will be forced to hire a licensed Florida attorney to represent you. Kahn &amp; Kahn has provided our clients both legal representation and the highest quality title insurance for nearly 50 years. Not all lawyers have experience in handling real estate transactions. You can depend upon our experience , acumen and reputation when navigating this very important process.</p>
    </div>
  </section>
  <section style={{background: 'url("images/real-estate-footer.png") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>
        </>
    );
}
export default RealEstateLaw;