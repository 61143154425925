import React from "react";
import { Link,NavLink } from "react-router-dom";
function Header(){
    return(
<header>
  <div className="main-header">
    <Link className="logo" to="/" title="khanandkhan">
      <img src="images/header-logo.png" alt="khan and khan logo" />
    </Link>
    <div className="tagline d-none d-lg-block font-lato mt-4">
      <span className="text-blue">WE PRACTICE LAW.</span><span className="text-purple">WE PRACTICE LIFE.<sup>®</sup></span>
    </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i className="fas fa-bars" />
    </button>
  </div>
  <div className="menu col-lg-11 col-12 m-auto">
    <nav className="navbar navbar-expand-lg">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav m-auto">
          <li className="nav-item">
            <NavLink className="nav-link active" aria-current="page" href="/">Home</NavLink>
          </li>
          
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Practice</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink className="dropdown-item" title="Our Practice" to="/our-practice">Our Practice</NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" title="Our Team" to="/the-team">Our Team</NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" title="Testimonials" to="/testimonials">Testimonials</NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact-us">Contact</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/our-office">Our Office</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
        );
}
export default Header;