import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import RealEstateLaw from './pages/RealEstateLaw';
import WillsTrust from './pages/WillsTrust';
import ProbateTrustAdministration from './pages/ProbateTrustAdministration';
import LandlordTenantLaw from './pages/LandlordTenantLaw';
import ContractsBusinessEntity from './pages/ContractsBusinessEntity';
import CriminalDefenseVictimRepresentation from './pages/CriminalDefenseVictimRepresentation';
import Guardianship from './pages/Guardianship';
import OurPractice from './pages/OurPractice';
import TheTeam from './pages/TheTeam';
import Testimonials from './pages/Testimonials';
import ContactUs from './pages/ContactUs';
import OurOffice from './pages/OurOffice';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <>
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/real-estate-law" element={<RealEstateLaw/>}></Route>
      <Route path="/wills-trust" element={<WillsTrust/>}></Route>
      <Route path="/probate-trust-administration" element={<ProbateTrustAdministration/>}></Route>
      <Route path="/landlord-tenant-law" element={<LandlordTenantLaw/>}></Route>
      <Route path="/contracts-business-entity" element={<ContractsBusinessEntity/>}></Route>
      <Route path="/criminal-defense-victim-representation" element={<CriminalDefenseVictimRepresentation/>}></Route>
      <Route path="/guardianship" element={<Guardianship/>}></Route>
      <Route path="/our-practice" element={<OurPractice/>}></Route>
      <Route path="/the-team" element={<TheTeam/>}></Route>
      <Route path="/testimonials" element={<Testimonials/>}></Route>
      <Route path="/contact-us" element={<ContactUs/>}></Route>
      <Route path="/our-office" element={<OurOffice/>}></Route>
    </Routes>
    </BrowserRouter>
    <Footer/>
    </>
    );
}

export default App;
