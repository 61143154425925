function OurPractice(){
    return(
        <>
<div>
  <section className="main-banner p-0">
    <img src="images/home-banner.png" alt="home banner" />
  </section>
  <section className="px-5 bg-purple text-center">
    <div className="content col-11 m-auto">
      <p className="text-light mb-0 fw-600">The Law Office of Kahn &amp; Kahn is a well established law firm originating in the City of Miami more than fifty years ago. The attorneys, Donald J. Kahn Esq. and his son, David J. Kahn Esq., together with their highly skilled and dedicated staff, are highly committed to providing first class legal services in a relaxed environment to their clients.
      </p>
    </div>
  </section>
  <section className="pb-0">
    <div className="col-md-11 m-auto col-12 bg-blue py-5 px-md-5 px-3 text-center case-eveluation">
      <h3 className="h3 text-light mb-4 fw-600">OUR TEAM</h3>
      <p className="text-light col-md-9 mx-auto col-12">Kahn &amp; Kahn has more than a half century presence in the City of Miami. Our team specializes in multiple practices of law including all aspects of real estate, estate planning, wills and trusts drafting, probate administration, contracts, corporate law and landlord /tenant transactions.</p>
      <div className="d-flex justify-content-center gap-4">
        <a className="btn btn-purple" href="the-team" title="GET TO KNOW THEM">GET TO KNOW THEM</a>
      </div>
    </div>
  </section>
  <section className="px-sm-5 px-3 pb-0 text-center">
    <h3 className="h3 text-blue mb-md-5 mb-3">WELL BALANCED</h3>
    <div className="row">
      <div className="col-lg-6 col-12 px-lg-4 px-2  mb-lg-0 mb-5">
        <div className="bg-light py-5 balanced-card">
          <div className="heading">
            <h6 className="subheading">We Practice</h6>
            <h3 className="h3 fw-600 text-blue">LAW</h3>
          </div>
          <div className="px-3 px-sm-5 mx-1 mt-4">
            <p className="mb-0">
              We are committed to honoring our Client Relationships, serving
              our clientele’s legal needs with Experience, Wisdom, Acumen &amp;
              Accessibility Our Clients are well protected and well informed
              at every stage of the Legal Process. It is our privilege to
              provide expert Legal Services, Comfort and Security to Our
              Clientele as we shepherd each Client through life’s passages,
              challenges and milestones.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 px-lg-4 px-2 ">
        <div className="bg-light py-5 balanced-card">
          <div className="heading">
            <h6 className="subheading">We Practice</h6>
            <h3 className="h3 fw-600 text-blue">LIFE</h3>
          </div>
          <div className="px-3 px-sm-5 mx-1 mt-4">
            <p className="mb-0">
              At Kahn and Kahn we Practice Life as we Practice Law – with
              Pure unbridled dedication to our social and human
              responsibilities. We strive to honor the Human Experience and
              Relationships in every column of our Firm Life and Life
              Practice(s). Our Firm Culture launches daily from a place of
              kindness, benevolence and civic responsibilities, Paying It
              Forward in our Cumulative Daily Practices of Law and Life.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="px-5 text-center">
    <h3 className="h3 text-blue mb-4 mb-lg-5">WELL RECOGNIZED</h3>
    <div className="reconized-slider owl-carousel">
      <div className="item">
        <img src="images/2019-president-circle.png" alt="2019 President Circle" />
      </div>
      <div className="item">
        <img src="images/fund-million.png" alt="fund million" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2013.png" alt="Fund pc 2013" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2014.png" alt="Fund pc 2014" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2015.png" alt="Fund pc 2015" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2017.png" alt="fund pc 2017" />
      </div>
      <div className="item">
        <img src="images/fund-tc-2013.png" alt="fund tc 2013" />
      </div>
      <div className="item">
        <img src="images/Fund-tc-2014.png" alt="Fund tc 2014" />
      </div>
    </div>
  </section>
  <section style={{background: 'url("images/law-bg.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default OurPractice;