function OurOffice(){
    return(
        <>
           <section className="px-5 text-center">
  <div className="row">
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-01.jpg">
        <img src="images/gallery/gallery-01.jpg" alt="office image 1" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-02.jpg">
        <img src="images/gallery/gallery-02.jpg" alt="office image 2" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-03.jpg">
        <img src="images/gallery/gallery-03.jpg" alt="office image 3" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-04.jpg">
        <img src="images/gallery/gallery-04.jpg" alt="office image 4" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-05.jpg">
        <img src="images/gallery/gallery-05.jpg" alt="office image 5" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-06.jpg">
        <img src="images/gallery/gallery-06.jpg" alt="office image 6" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-07.jpg">
        <img src="images/gallery/gallery-07.jpg" alt="office image 7" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-08.jpg">
        <img src="images/gallery/gallery-08.jpg" alt="office image 8" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-08.jpg">
        <img src="images/gallery/gallery-08.jpg" alt="office image 9" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-09.jpg">
        <img src="images/gallery/gallery-09.jpg" alt="office image 10" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-10.jpg">
        <img src="images/gallery/gallery-10.jpg" alt="office image 11" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-11.jpg">
        <img src="images/gallery/gallery-11.jpg" alt="office image 12" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-12.jpg">
        <img src="images/gallery/gallery-12.jpg" alt="office image 13" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-13.jpg">
        <img src="images/gallery/gallery-13.jpg" alt="office image 14" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-14.jpg">
        <img src="images/gallery/gallery-14.jpg" alt="office image 15" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-15.jpg">
        <img src="images/gallery/gallery-15.jpg" alt="office image 16" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-16.jpg">
        <img src="images/gallery/gallery-16.jpg" alt="office image 17" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-17.jpg">
        <img src="images/gallery/gallery-17.jpg" alt="office image 18" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-18.jpg">
        <img src="images/gallery/gallery-18.jpg" alt="office image 19" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-19.jpg">
        <img src="images/gallery/gallery-19.jpg" alt="office image 20" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-20.jpg">
        <img src="images/gallery/gallery-20.jpg" alt="office image 21" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-21.jpg">
        <img src="images/gallery/gallery-21.jpg" alt="office image 22" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-22.jpg">
        <img src="images/gallery/gallery-22.jpg" alt="office image 23" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-23.jpg">
        <img src="images/gallery/gallery-23.jpg" alt="office image 24" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-24.jpg">
        <img src="images/gallery/gallery-24.jpg" alt="office image 25" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-25.jpg">
        <img src="images/gallery/gallery-25.jpg" alt="office image 26" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-26.jpg">
        <img src="images/gallery/gallery-26.jpg" alt="office image 27" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-27.jpg">
        <img src="images/gallery/gallery-27.jpg" alt="office image 28" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-28.jpg">
        <img src="images/gallery/gallery-28.jpg" alt="office image 29" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-29.jpg">
        <img src="images/gallery/gallery-29.jpg" alt="office image 30" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-30.jpg">
        <img src="images/gallery/gallery-30.jpg" alt="office image 31" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-31.jpg">
        <img src="images/gallery/gallery-31.jpg" alt="office image 32" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-32.jpg">
        <img src="images/gallery/gallery-32.jpg" alt="office image 33" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-33.jpg">
        <img src="images/gallery/gallery-33.jpg" alt="office image 34" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-34.jpg">
        <img src="images/gallery/gallery-34.jpg" alt="office image 35" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-35.jpg">
        <img src="images/gallery/gallery-35.jpg" alt="office image 36" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-36.jpg">
        <img src="images/gallery/gallery-36.jpg" alt="office image 37" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-37.jpg">
        <img src="images/gallery/gallery-37.jpg" alt="office image 38" />
      </a>
    </div>
    <div className="col-sm-4 col-6 p-2">
      <a data-fancybox="gallery" href="images/gallery/gallery-38.jpg">
        <img src="images/gallery/gallery-38.jpg" alt="office image 1" />
      </a>
    </div>
  </div>
</section>

        </>
    );
}
export default OurOffice;