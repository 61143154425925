function Footer(){
  let scrollTop = (()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
    return(
        <>
<div>
  <footer className="bg-blue p-5">
    <div className="row align-items-center">
      <div className="col-md-4 col-12">
        <div className="footer-menu">
          <ul>
            <li><a className="footer-link" href="index.html" title="Home">Home</a></li>
            <li><a className="footer-link" href="practice.html" title="Practice">Practice</a></li>
            <li><a className="footer-link" href="our-team.html" title="Our Team">Our Team</a></li>
            <li><a className="footer-link" href="testimonials.html" title="Testimonials">Testimonials</a></li>
            <li><a className="footer-link" href="contact.html" title="Contact">Contact</a></li>
            <li className="footer-link">Liora H Cannon Consulting llc</li>
            <li className="footer-link">Brand Identity – Web Design – Interior Design</li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 col-12 my-5 my-md-0">
        <a className="footer-logo" href="index.html"><img src="images/footer-logo.png" alt="khan and khan logo" /></a>
      </div>
      <div className="col-md-4 col-12">
        <div className="address">
          <ul>
            <li className="footer-address">Contact Us</li>
            <li className="footer-address">
              O. 305.865.4311 <br />
              F. 305.866.7506 <br />
              1133 Normandy Drive <br />
              Miami Beach, FL 33141 <br />
              ©2021 Kahn &amp; Kahn P.L. Attorneys at Law
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <button onClick={scrollTop} id="goToTopBtn" title="Go to top"><i className="fas fa-arrow-up" /></button>
</div>
      </>
    );
}
export default Footer;