function ContractsBusinessEntity(){
    return(
        <>
         <div>
  <section style={{background: 'url("images/contracts_paper_signing.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Contracts &amp; Business Entity</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Contracts are agreements between persons or legal entities (e.g.- corporations), in which one party agrees to perform a service or provide goods in exchange for the payment of money or other goods or services. Corporations, Partnerships and Limited Liability Companies are valuable tools which can assist in liability protection and potential tax benefits in business and real estate industries.</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/contracts_contracts_icons.png" alt="real estate contracts contracts icons" />
      <h3 className="h3 m-4 text-blue fw-400">CONTRACT</h3>
      <p>The formation of a contract is accomplished when there is an offer and acceptance between the contracting parties. This offer and acceptance is sometimes referred to as a “meeting of the minds.”</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/contracts_business_entities_icons.png" alt="real estate contracts business entities icons" />
      <h3 className="h3 m-4 text-blue fw-400">BUSINESS ENTITY</h3>
      <p>A business entity is an organization created by one or more natural persons to carry on a trade or business. Some business entities are considered for federal tax purposes to be not separate from its owner. Such is the case with single-member limited liability companies and sole proprietors.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center">
      <img src="images/contracts_business_entity_formation_icon.png" alt="real estate contracts business entity formation icon" />
      <h3 className="h3 m-4 text-blue fw-400">CONTRACTS &amp; BUSINESS ENTITY FORMATION CLOSER LOOK</h3>
      <p>Most printed contracts are drafted by lawyers and are designed to protect their client’s rights. Each year, Florida residents lose money because they do not understand contracts when they enter into them or what to do when the other side breaches the agreement. In many of these cases, the advice of a lawyer would have prevented the loss of money.</p>
      <p>Before you enter an agreement requiring the giving or payment of valuable consideration, it is best to have a qualified lawyer review the agreement, explain your obligations under it, and the consequence of a breach of the agreement.</p>
      <p>Never sign anything that you have not read nor fully understand. There are some benefits and some drawbacks regarding the different types of business entities and their intended uses. In some situations, the proper business structure can save you years of litigation or significant amounts of money in tax savings.</p>
      <p>Kahn &amp; Kahn Legal Professionals are ready to assist you with your contract and business entity needs. Please contact us for your complimentary consult regarding these business law concerns and questions.</p>
    </div>
  </section>
  <section style={{background: 'url("images/footer_contracts.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default ContractsBusinessEntity;