function Testimonials(){
    return(
        <>
        <div>
  <section style={{background: 'url("images/inner-testimonials-bg.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner testimonials-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Testimonials</h1>
    </div>
  </section>
  <section className="text-center pt-4 testimonials-page">
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>“Your good deed of the day gave me so much peace of mind. I truly thank you for it. All of my appreciation.”</p>
          <p className="name fw-600">Rachel &amp; Imelda</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Please accept my appreciation for everything you’ve done to help me achieve the American dream. You’ve got a friend for life. Thank you and your staff.</p>
          <p className="name fw-600">Anton</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you for this special presentation. God is always just in time and our supporters are the greatest people in the world!</p>
          <p className="name fw-600">Ron &amp; Marilyn</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">The amazing Kahn Crew (Donald, David, Vanessa and other staff members) was the highlight of this Loan Closing. The welcome and warmth that we felt in your office is rare and indescribable! Thank you very much for making my family and I feel so at home. I am very impressed with Vanessa’s services; she is very knowledgeable, efficient and prompt! She sealed the deal with all the hundreds of signatures! I could not have done it without you all – God bless each and everyone of you for all your great effort and support!!!!</p>
        <p className="name text-light mb-0 fw-600">Angela</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you for all of your time and special attention to my case.</p>
          <p className="name fw-600">Imelda</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Just a note to thank all of you in helping us with securing the apartment. You were all so professional and as important so very nice and cooperative.</p>
          <p className="name fw-600">Steve</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Again, Don you were great. You have not lost your touch. Still the Best.</p>
          <p className="name fw-600">Milagros</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Mere words of sincere appreciation do not adequately express the relief you and your staff have provided in our unique situation. You have far exceeded our expectations. We will, without hesitation, recommend your firm with confidence and trust you in all our future business affairs.</p>
        <p className="name text-light mb-0 fw-600">Damon &amp; Kathleen</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I appreciate all you did on my closing. You are most efficient and professional and made the process go smoothly as a consequence.</p>
          <p className="name fw-600">Carol</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>It was our pleasure to meet you both today. Agi and I wish to thank you for all your efforts with the legal work for the sale of our condominium. Again, thanks for your expertise and professionalism.</p>
          <p className="name fw-600">Jack</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>During my 35 years of business experience it has been very rare to work with an office that is so ethical, knowledgeable and professional.</p>
          <p className="name fw-600">Robert</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">I so appreciate that you have been there for me. I know I bother you too much but your love is always there. Thank you so much! That is why for 15 years you guys are the ones I choose to be the closing company for my clients and myself. Love u both with gratitude.</p>
        <p className="name text-light mb-0 fw-600">Yvette</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Mi Gracias por todo. Estoy muy agradecida contigo.</p>
          <p className="name fw-600">Soroya</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>It was the first transaction for us in the US and you all made it very pleasant. We are sure that there will be more coming up in the near future. Thank you very much everyone!</p>
          <p className="name fw-600">Ana, Matias &amp; Soledad</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you very much for all of your help!</p>
          <p className="name fw-600">Vivian</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Heartfelt gratitude for your many human kindnesses, professional expertise, sincere care and assistance and accommodations extended to me as your client yesterday. My experiences as your client are exceptional! I am fortunate to receive your expert advocacy, guidance counsel and human CARE. With blessings and peace.</p>
        <p className="name text-light mb-0 fw-600">LC</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Dear Vanessa, We cannot thank you enough for all your help and effort to make this house dream happen. Best Wishes.</p>
          <p className="name fw-600">Leslie &amp; Michael</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Vanessa thank you so much one more time for your great job, also for helping our customer efficiently as always. Looking to working with you on everyone of my deals. Have an amazing week from our team.</p>
          <p className="name fw-600">Maylin</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I just wanted to thank you and your team for all of your help with our closing. You guys made it extremely easy on us and we sincerely appreciate it.</p>
          <p className="name fw-600">Juan</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">I am writing to thank you for the outstanding work you did for me. You have been very pleasant, courteous and helpful in your dealings with me. You always explain things in the most understandable way. It has been a pleasure to work with you, thank you for your patience and kindness.</p>
        <p className="name text-light mb-0 fw-600">Soraya</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Hi David, It was a pleasure meeting you today. Thank you so much for all of your help, you were so helpful. Thank you also for your advice. I felt empowered as I left your office.</p>
          <p className="name fw-600">Gail</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Donald, you truly have a special way to turn a difficult situation into a success. Thank you for always being a true professional and gentleman.</p>
          <p className="name fw-600">Richard</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Sara, I want to thank you for all the work you did in transferring my Co-op Apt. share certificate etc. You made a difficult transaction run smoothly &amp; professionally and your work is most appreciated.</p>
          <p className="name fw-600">Roy</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">It was a real pleasure meeting you in person today. Irina and I are very happy to conclude this purchase which could not have happened without the diligent and professional approach you and your team had. Even today at closing we had a far better experience than when we purchased our home in the Hamptons 18 months ago. Please extend our thanks to Sarah as well.</p>
        <p className="name text-light mb-0 fw-600">Mohammed</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Always a pleasure doing business with your company! Great Job! See you next closing! Best Wishes.</p>
          <p className="name fw-600">Lisa</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I would like to take this opportunity to thank you and your staff, especially Jose Mairena, for your time and efforts on being so instrumental in successfully closing this transaction. I appreciate your cooperation and look forward to working with you again in the future.</p>
          <p className="name fw-600">Nelson</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you so much for all of your work on the file. We really appreciate how efficient you’ve been throughout the transaction.</p>
          <p className="name fw-600">Joy</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Please accept my thanks and heartfelt appreciation for your hard work, energies, Professional Expertise and provided attentions today as you worked tirelessly with multiple parties to put out fires, address and resolve scheduling snafus and issues. I am most fortunate to be Your Client, please know that the Professional Expertise, superior CARE, Integrities and attentions provided by You and David Kahn are not taken for granted, each remain Truly Cherished, esteemed components of my GK Client Experience. With gratitude, always.</p>
        <p className="name text-light mb-0 fw-600">L.C.</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you so much for all of your help. May the lord always bless you.</p>
          <p className="name fw-600">Miffiri</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you Vanessa and your team!! We appreciated how quickly and seamlessly you made the process. We will certainly recommend your firm and will use your services should we need to in the future.</p>
          <p className="name fw-600">Espy &amp; Alfredo</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>To the BEST attorneys and paralegals on earth! Thinking of you guys and sending blessings.</p>
          <p className="name fw-600">Martha</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Dear David, You were very hospitable and extremely gracious to my mother, son and myself. We want to say thank you very much for the kindness shown and the professional services provided. It is our fervent prayer that you will be blessed you as you have blessed us.</p>
        <p className="name text-light mb-0 fw-600">Anthony</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Mr. David Kahn, Thank you for your professionalism, patience and kindness.</p>
          <p className="name fw-600">Maria</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Once again I’d like to praise you for Jose’s work and diligence in this transaction. He made my first REO a breeze.</p>
          <p className="name fw-600">Patricia</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Sara, my very long list of gratitude has your name on it. Sincerely.</p>
          <p className="name fw-600">Lisa</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Thank you for your kindness and professionalism to my customers. They are new to me and Donald, yourself and your office made for a very happy ending to this young couple. Again, as a real estate broker it is nice to feel all is in good hands in your office, with much appreciation.</p>
        <p className="name text-light mb-0 fw-600">Carol</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you for helping us with the closing. Sara did a great job! She might deserve a raise ?</p>
          <p className="name fw-600">Sharon &amp; James</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you David for making your law firm one of the best on Miami Beach.</p>
          <p className="name fw-600">Paul</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>You and the other members of your office did a really good job at this closing. I will definitely hire you every time I need legal services in the future.</p>
          <p className="name fw-600">Alejandro</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Now that the dust has settled I want to thank you both for making the process as do-able as possible at 3,000 miles. I was under great stress, fearing at any moment that the deal might fall through, but it gave me great comfort to have you both in my corner. Thank you again</p>
        <p className="name text-light mb-0 fw-600">Bobby</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I want to thank you all for the wonderful work you have done handling the trusts and the sale of the condominium. You are all greatly appreciated!</p>
          <p className="name fw-600">Richard</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thank you all for your assistance with this matter. I could not have asked for a better team to help me navigate these waters and I appreciate how easy you have made the whole process.</p>
          <p className="name fw-600">Greg</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I wanted to take a moment to say thanks again for doing what you do so well … Another satisfied customer. I appreciate all you do to get these deals to the closing table!</p>
          <p className="name fw-600">Judi</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Vanessa, thank you!! As always you did a great job and we are super happy with the way you handled the process. It was a difficult task but you are very professional and efficient. Thank you!!Vanessa, thank you!! As always you did a great job and we are super happy with the way you handled the process. It was a difficult task but you are very professional and efficient. Thank you!!</p>
        <p className="name text-light mb-0 fw-600">Zackie</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>To Donald and Sara, we thank you for your time and consideration with the purchase of our own little piece of Miami Beach.</p>
          <p className="name fw-600">Correna &amp; Ken</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Sara, thank you so much for all of your help through this process. You were kind, professional and a pleasure to work with.</p>
          <p className="name fw-600">Christine</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>We are so grateful for your service and professionalism in this process. Thank you!</p>
          <p className="name fw-600">Doris &amp; Keshia</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Bought a property without an agent so needed an experienced real estate attorney to help guide the process. David and his team were unbelievably helpful and super easy to work with.</p>
        <p className="name text-light mb-0 fw-600">Stephen C.</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Natalia and Vanessa, I just wanted to say THANK YOU to both of you again for the amazing job you did with the Diaz/Ramirez closing. You are both true professionals in your field. I know this deal would not have been possible without your amazing patience and dedication. You went above and beyond to ensure this deal closed and the Fonseca Team and the Daiz Family are grateful. You are both truly amazing.</p>
          <p className="name fw-600">Karla</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Thanks Kelle. You were such a pleasure to work with ? I suspect my brother and I will see you again soon for the next project.</p>
          <p className="name fw-600">Eddie</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I have been working with Donald Kahn and his amazing team of dedicated staff for the past 18 years. They are simply wonderful. They not only make sure to give you the best possible professional services but they make sure you feel like family. They make everything seem so easy, I don’t know how they do it! I am sure glad I have this amazing Law Firm protecting my interests! To Donald and the rest of the team, thank you for everything!</p>
          <p className="name fw-600">Damarys</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Hello Natalia, Hope all is great with you. Please ask David if he would like to suggest a good CPA nearby. We realized the professionals he works with are very close in essence to the ethos he has. Also, let me thank your firm for the outstanding legal assistance we get from you all. Best regards.</p>
        <p className="name text-light mb-0 fw-600">Alberto</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Donald has been my mothers attorney for many years. He is the best. Very knowledgeable, he takes his time to explain the legal terms and is very quick to solve the issues at hand. Thank you!</p>
          <p className="name fw-600">David N.</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Hi All, We did it! As the dust starts to settle, I just wanted to take a minute and say THANK YOU for all your hard work making this transaction happen. I know it wasn’t easy, and bit touch-and-go at times (to say the least). I sincerely appreciate all your efforts and assistant in making my goal and dream a reality. You guys were Amazing! Perfect execution!! Many thanks and best regards!</p>
          <p className="name fw-600">Jordan</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Millie, thank you so much for your hard work and dedication and for making this deal happen so smoothly!!!</p>
          <p className="name fw-600">Vanesa C.</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">Thank you Natalia, you are the best. I just got off the phone with Maria Pavich, and she was super happy with all of us and the entire loan process.</p>
        <p className="name text-light mb-0 fw-600">Paul Greenblat</p>
      </div>
    </div>
    <div className="row px-md-5 px-0 m-0">
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>I would like to say thank you to Kelly for all the help and effort in this closing. The buyers were so stressed, it was lots of work! Kelly thanks for your patience, is really important when you are having a complicated closing to be working with a good team.</p>
          <p className="name fw-600">Luciana</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>You really have a very impressive firm. Over the course of the past 45 or so years I’ve had occasion to close with your office and it’s always been a very professional experience. Today was no exception. Sara is super competent and a pleasure to work with.</p>
          <p className="name fw-600">Dennis</p>
        </div>
      </div>
      <div className="col-sm-2 col-md-4 col-12 my-md-5 my-3">
        <div className="testimonial-card px-4">
          <div className="quote-icon"><i className="fas fa-quote-right" /></div>
          <p>Sara , It was a genuine pleasure doing business with you. The closing last Wednesday was a real pleasure despite the numerous forms to sign ! You were very detailed, easy to understand and I would definitely recommend you and your firm to anyone looking to buy property.</p>
          <p className="name fw-600">Christopher</p>
        </div>
      </div>
    </div>
    <div className="bg-blue p-5">
      <div className="testimonial-card col-md-6 m-auto col-12">
        <div className="quote-icon"><i className="fas fa-quote-right" /></div>
        <p className="text-light">From the first conversation that we had with Vanessa Colon we were satisfied she knew what she was doing and would get the job done. This was the transaction from Hades, but Vanessa figured how to get through it all and directed all parties to get to closing. We thank you, and especially Venessa for her abilities, professionalism, and work ethic. We seriously hope that we can work out the longistics of possible future closing that come our way and get the opportunity to work with her again.</p>
        <p className="name text-light mb-0 fw-600">Paul Greenblat</p>
      </div>
    </div>
  </section>
  <section className="pt-0">
    <div className="col-md-11 m-auto col-12 bg-blue py-5 px-md-5 px-3 text-center case-eveluation">
      <h3 className="h3 text-light mb-4 fw-600">WE WILL EXCEED YOUR EXPECTATIONS</h3>
      <div className="d-flex justify-content-center gap-4">
        <a className="btn btn-purple" href="contact-us" title="Contact Info">Contact Us</a>
        <a className="btn btn-purple" href="tel:305-865-4311" title="Call Now"><i className="fas fa-phone-alt me-3" />Call Now</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default Testimonials;