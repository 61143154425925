function Guardianship(){
    return(
        <>
        <div>
  <section style={{background: 'url("images/gardianship-header-sm.png") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Guardianship</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">A Guardianship is a legal proceeding in the circuit courts of Florida in which a Guardian is appointed to exercise the legal rights of an incapacitated person.</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/guardianship_guardian_icons.png" alt="real estate guardianship guardian icons" />
      <h3 className="h3 m-4 text-blue fw-400">THE GUARDIAN</h3>
      <p>A Guardian is an individual or institution (such as a nonprofit corporation or bank trust department) appointed by the court to care for an incapacitated person — called a ‘ward’ — or for the ward’s assets.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/guardianship_ward_icons.png" alt="real estate guardianship ward icons" />
      <h3 className="h3 m-4 text-blue fw-400">THE WARD</h3>
      <p>An incapacitated person is an adult who has been judicially determined to lack the capacity to manage at least some of his or her property or to meet at least some essential health and safety requirements.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center">
      <img src="images/guardianship_closerlook_icon.png" alt="real estate guardianship closerlook icon" />
      <h3 className="h3 m-4 text-blue fw-400">GUARDIANSHIP CLOSER LOOK</h3>
      <p>Any adult resident of Florida, related or unrelated to the potential ward, can serve as a guardian. A guardian who is given authority over property of the ward is required to inventory the property, invest it prudently, use it for the ward’s support and account for it by filing detailed annual reports with the court. In addition, the guardian must obtain court approval for certain financial transactions.</p>
      <p>The Guardian of the ward’s person may exercise those rights that have been removed from the ward and delegated to the guardian, such as providing medical, mental and personal care services and determining the place and kind of residential setting best suited for the ward. Annually, the Guardian of the person must also present to the court a detailed plan for the ward’s care along with a physician’s report.</p>
      <p className="mb-0">We have extensive experience assisting family members and concerned loved ones as they navigate the Guardianship process. Please contact us to make an appointment to discuss how we can assist with your guardianship needs.</p>
    </div>
  </section>
  <section style={{background: 'url("images/footer_guardianship.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default Guardianship;