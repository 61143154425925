function WillsTrust(){
    return(
        <>
<div>
  <section style={{background: 'url("images/miami-wills-trust-lawyer.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Wills &amp; Trusts</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Many times, when people have children, buy a home or experience the passing of a loved one, the topic of an Estate Plan presents. A properly crafted Estate Plan is one of the most important things you can do to provide stability for your loved ones when you are gone.</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/wills.png" alt="real estate wills" />
      <h3 className="h3 m-4 text-blue fw-400">WILLS</h3>
      <p className="mb-1">A will is a written direction controlling the disposition of property at death. </p>
      <p className="mb-1">The laws of each state set the formal requirements for a legal will. </p>
      <p className="mb-1">Wills are required to be admitted to court after death and track the lengthy probate process.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/willandtrust_trusts_icon.png" alt="real estate willandtrust trusts icon" />
      <h3 className="h3 m-4 text-blue fw-400">TRUSTS</h3>
      <p>A Trust is similar to a will in the sense that A TRUST documents your wishes regarding the distribution of assets. A Trust creates and ensures a far more efficient process for your loved ones after your passing. A properly drafted Trust will allow your beneficiaries to avoid the probate proceedings saving your family time and expense.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/willandtrust_closerlook_icon.png" alt="real estate willandtrust closerlook icon" />
      <h3 className="h3 m-4 text-blue fw-400">WILLS &amp; TRUSTS CLOSER LOOK</h3>
      <p>When the time comes to have this very important conversation, you should be guided by a professional with the proper experience to help you achieve your goals. An estate plan prepared by a skilled lawyer will allow you to rest assured that your loved ones will be taken care of. Should you fail to make a will or a trust (this is called dying “intestate”) your assets will be distributed according to a formula set by law. Such tools as Powers of Attorney, living wills, designations of healthcare surrogates will aid in the crafting of the final plan. Please be aware that should you fail to make the proper plans, the inheritance statute will determine what befalls your Property and Assets, rather than You directing and ensuring how the details of your Estate unfold. At Kahn &amp; Kahn our approach is comprehensive and personalized to each individual situation. The first step to creating a will or trust is by scheduling a time to review the particulars of your situation. Please Contact us today to begin this most important conversation.</p>
    </div>
  </section>
  <section style={{background: 'url("images/wills-footer.png") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>
        </>
    );
}
export default WillsTrust;