function LandlordTenantLaw(){
    return(
        <>
            <div>
  <section style={{background: 'url("images/landlord_rental_agreement_signing.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Landlord-Tenant Law</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Landlord-tenant laws affect all aspects of a tenancy, from move-in (starting with tenant screening) to move out (when and how a tenancy ends).</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/probate_probate_icon.png" alt="real estate probate probate icon" />
      <h3 className="h3 m-4 text-blue fw-400">LANDLORD</h3>
      <p>When you rent a house, apartment or mobile home to another person, you enter into a legal contract known as a rental agreement. Your obvious right as a landlord is to receive rent for the use of the property. As landlord it is also your right is to have your property returned to you undamaged at the end of the agreement.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/probate_personalrepresentative_icon.png" alt="real estate probate personalrepresentative icon" />
      <h3 className="h3 m-4 text-blue fw-400">TENANT</h3>
      <p>When a person pays to live in a house, apartment or mobile home the Renter becomes a tenant governed by Florida law. A Tenant has certain basic rights protected by Florida law which the landlord must observe.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center">
      <img src="images/probate_successor_trustee_icon.png" alt="real estate probate successor trustee icon" />
      <h3 className="h3 m-4 text-blue fw-400">LANLORD-TENANT LAW CLOSER LOOK</h3>
      <p>The landlord/tenant relationship is a legal contract, you should understand its various provisions before you rent your property to anyone. As a landlord you will be required to provide living quarters that are safe and kept in good repair. At the end of the rental term, the tenant must return the property with no damage beyond ordinary wear and tear. Both landlords and tenants have specific rights and responsibilities to each-other under state law.</p>
      <p>Let us help you be the best landlord possible and to make ensure that if you are a Tentant /lessee , you preserve your rights and understand your responsibilities. At Kahn &amp; Kahn we are able to help both landlords and tenants to understand their respective duties and responsibilities and to resolve any issues that may arise during these relationships. Should a landlord-tenant dispute end up in court, in an eviction or personal injury lawsuit, both landlord and tenant will require an experienced attorney to represent them. Please contact us directly if you would like to discuss any potential landlord tenant matter. We look forward to our complimentary consult discussion with you regarding your Landlord – Tenant concerns and questions .</p>            
    </div>
  </section>
  <section style={{background: 'url("images/footer_landlord.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>


        </>
    );
}
export default LandlordTenantLaw;