function TheTeam(){
    return(
        <>
       <div>
  <section style={{background: 'url("images/inner-ourteam-bg.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner team-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Our Team</h1>
    </div>
  </section>
  <section className="p-5 text-center team-page">
    <div className="row">
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <a href="#" title="DONALD J. KAHN ESQ.">
            <div className="img-sec"><img src="images/team/donald-kahn.jpg" alt="ava soren" /></div>
            <div className="name">DONALD J. KAHN ESQ.</div>
          </a>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <a href="#" title="David J. Kahn Esq.">
            <div className="img-sec"><img src="images/team/david-kahn.jpg" alt="ava soren" /></div>
            <div className="name">David J. Kahn Esq.</div>
          </a>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <a href="#" title="Max Soren ESQ.">
            <div className="img-sec"><img src="images/team/max-soren.png" alt="ava soren" /></div>
            <div className="name">Max Soren ESQ.</div>
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/ryan-fitzgerald.jpg" alt="ryan fitzgerald" /></div>
          <div className="name">RYAN E. FITZGERALD</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/natashawest.jpg" alt="natasha west" /></div>
          <div className="name">NATASHA WEST</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/kayla-garcia.png" alt="KAYLA GARCIA" /></div>
          <div className="name">KAYLA GARCIA</div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/leslie-cardenas.png" alt="leslie cardenas" /></div>
          <div className="name">LESLIE CARDENAS</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/barbaro-pino.png" alt="barbaro pino" /></div>
          <div className="name">BARBARO PINO</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/ava-soren.jpg" alt="ava soren" /></div>
          <div className="name">AVA SOREN</div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/mylene.jpg" alt="mylene" /></div>
          <div className="name">MYLENE C. SANTANA</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/bianca-mexume.png" alt="bianca mexume" /></div>
          <div className="name">BIANCA MEXUME</div>
        </div>
      </div> 
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card">
          <div className="img-sec"><img src="images/team/kaylamartinez.jpg" alt="kayla martinez" /></div>
          <div className="name">KAYLA MARTINEZ</div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-12 mb-4">
        <div className="team-card bg-purple apply-card">
          <a href="contact-us" title="APPLY HERE">
            <p className="text-light p-5 m-0">Kahn &amp; Kahn is always seeking driven, detail-oriented professionals to join our staff.</p>
            <div className="name">APPLY HERE</div>
          </a>
        </div>
      </div>
    </div>
  </section>
  <section style={{background: 'url("images/flag-bg.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default TheTeam;