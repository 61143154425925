function ProbateTrustAdministration(){
    return(
        <>
<div>
  <section style={{background: 'url("images/probate_scales_of_justice.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Probate &amp; Trust Administration</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Probate is a court-supervised process for identifying and gathering the assets of a deceased person (decedent), paying the decedent’s debts and distributing the decedent’s assets to his or her beneficiaries. Should the decedent establish what is commonly referred to as a “Revocable Trust,” Kahn &amp; Kahn is available to assist the Successor Trustee administer the trust when the time comes to do so.</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/probate_probate_icon.png" alt="real estate probate probate icon" />
      <h3 className="h3 m-4 text-blue fw-400">PROBATE ASSETS</h3>
      <p>Probate administration applies only to probate assets. Probate assets are those assets that the decedent owned in his or her sole name at death or were assets owned by the decedent and one or more co-owners which lacked a provision for automatic succession of ownership at death.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/probate_personalrepresentative_icon.png" alt="real estate probate personalrepresentative icon" />
      <h3 className="h3 m-4 text-blue fw-400">PERSONAL REPRESENTATIVE</h3>
      <p>The personal representative is the person, bank, or company appointed by the Judge to be in charge of the administration of the decedent’s probate estate. In Florida, the terms “personal representative, executor, executrix, administrator and administratrix” are commonly used to identify this individual.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/probate_successor_trustee_icon.png" alt="real estate probate successor trustee icon" />
      <h3 className="h3 m-4 text-blue fw-400">SUCCESSOR TRUSTEE</h3>
      <p>When an individual passes the established Trust will provide for a Successor Trustee to assume responsibility for the trust management and distribution of assets after death. The Successor Trustee has many duties and responsibilities which are established in the trust document.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center">
      <img src="images/probate_closer_look_icon.png" alt="real estate probate closer look icon" />
      <h3 className="h3 m-4 text-blue fw-400">PROBATE &amp; TRUST ADMINISTRATION CLOSER LOOK</h3>
      <p>The loss of a loved one and the ensuring probate process can be a long and frustrating endeavor.</p>
      <p>The Legal Professional Team at Kahn &amp; Kahn will guide you and your family through each of the Probate steps and processes with care and explanations. We will ensure that each Probate and Trust detail is handled in the most professional and expedited manner. Accepting the role of Successor Trustee for a loved one comes with great responsibility. We are here to assist in any way needed to make sure that you are able to navigate these challenges with a great team ready to support you. Please don’t hesitate to contact us should you have any questions or would like to setup a complimentary consultation, we invite your inquiries and questions, and look forward to discussing your concerns with you.</p>
    </div>
  </section>
  <section style={{background: 'url("images/probate-footer.png") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>

        </>
    );
}
export default ProbateTrustAdministration;