function ContactUs(){
    return(
        <>
          <div>
  <section style={{background: 'url("images/inner-contact-bg.jpg") no-repeat', backgroundSize: 'cover', padding: '120px 0px !important'}} className="inner-banner contact">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Contact Us</h1>
    </div>
  </section>
  <section className="text-center">
    <h6 className="h6 text-blue mb-3 font-montserrat">Arrange Your complimentary consult with a Kahn &amp; Kahn Legal Professional today.</h6>
    <p className="mb-3 fw-600">We look forward to speaking with You.</p>
    <p className="fw-600">Se Habla Español.</p>
    <div className="row">
      <div className="col-6 mt-5">
        <a href="tel:305-865-4311">
          <img className="contact-icon" src="images/phone-icon.png" alt="phone icon" />
          <h6 className="h6 mt-4 text-purple">305.865.4311</h6>
        </a>
      </div>
      <div className="col-6 mt-5">
        <img className="contact-icon" src="images/clock-icon.png" alt="clock icon" />
        <h6 className="h6 mt-4 text-purple">WEEKDAYS 9AM-5PM</h6>
      </div>
    </div>
  </section>
  <section className="contact-sec">
    <div className="col-md-9 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light fw-700 mb-4">CALL REQUEST</h3>
      <div className="contact-form">
        <form id="contactForm" action="mail.php" method="POST">
          <div className="form-group">
            <label>Your Name (required)</label>
            <input type="text" id="name" name="name" placeholder />
            <label id="nameError" className="error" />
          </div>
          <div className="form-group">
            <label>Your Email (required)</label>
            <input type="email" id="email" name="email" placeholder />
            <label id="emailError" className="error" />
          </div>
          <div className="form-group">
            <label>Subject</label>
            <input type="text" id="subject" name="subject" placeholder />
            <label id="subjectError" className="error" />
          </div>
          <div className="form-group">
            <label>Your Message</label>
            <textarea id="message" name="message" defaultValue={""} />
            <label id="messageError" className="error" />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-purple w-100">Send</button>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section className="text-center">
    <h4 className="h4 text-blue font-montserrat text-center">1133 Normandy Dr. Miami Beach, FL 33141</h4>
    <a className="btn btn-purple mt-4" href="https://goo.gl/maps/HX54ya1aU5Kqciu96" style={{}} aria-label="Visit Us" data-gdpr-atts="{}" target="_blank">Visit Us</a>
  </section>
  <section className="p-0 map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.411022334173!2d-80.13291542390293!3d25.855946304703096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b2e5180a5421%3A0xb4ac94c3eb72f28!2s1133%20Normandy%20Dr%2C%20Miami%20Beach%2C%20FL%2033141%2C%20USA!5e0!3m2!1sen!2sin!4v1710616397252!5m2!1sen!2sin" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
  </section>
</div>

        </>
    );
}
export default ContactUs;