function Home(){
    return(
        <>
  <section className="main-banner p-0">
    <img src="images/home-banner.png" alt="home banner" />
  </section>
  <section className="px-3 text-center">
    <div className="content">
      <h3 className="h3 text-blue mb-4"> WE PRACTICE LAW. WE PRACTICE LIFE.<sup>®</sup></h3>
      <p className="mb-3"> We are committed to each of tenets which drive Kahn and Kahn’s Practice of Law and Practice of Life. </p>
      <p> We practice Law with firm dedication to honoring and serving the client experience. </p>
    </div>
    <div className="more">
      <a className="more-btn" href="practice.html">Learn More</a>
    </div>
  </section>
  <section className="px-sm-5 px-3 text-center bg-blue">
    <h3 className="h3 text-white mb-md-5 mb-3">WELL BALANCED</h3>
    <div className="row">
      <div className="col-lg-6 col-12 px-lg-4 px-2  mb-lg-0 mb-5">
        <div className="bg-light py-5 balanced-card">
          <div className="heading">
            <h6 className="subheading">We Practice</h6>
            <h3 className="h3 fw-600 text-blue">LAW</h3>
          </div>
          <div className="px-3 px-sm-5 mx-1 mt-4">
            <p className="mb-0">
              We are committed to honoring our Client Relationships, serving
              our clientele’s legal needs with Experience, Wisdom, Acumen &amp;
              Accessibility Our Clients are well protected and well informed
              at every stage of the Legal Process. It is our privilege to
              provide expert Legal Services, Comfort and Security to Our
              Clientele as we shepherd each Client through life’s passages,
              challenges and milestones.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 px-lg-4 px-2 ">
        <div className="bg-light py-5 balanced-card">
          <div className="heading">
            <h6 className="subheading">We Practice</h6>
            <h3 className="h3 fw-600 text-blue">LIFE</h3>
          </div>
          <div className="px-3 px-sm-5 mx-1 mt-4">
            <p className="mb-0">
              At Kahn and Kahn we Practice Life as we Practice Law – with
              Pure unbridled dedication to our social and human
              responsibilities. We strive to honor the Human Experience and
              Relationships in every column of our Firm Life and Life
              Practice(s). Our Firm Culture launches daily from a place of
              kindness, benevolence and civic responsibilities, Paying It
              Forward in our Cumulative Daily Practices of Law and Life.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="px-5 text-center">
    <h3 className="h3 text-blue mb-4 mb-lg-5">WELL RECOGNIZED</h3>
    <div className="reconized-slider owl-carousel">
      <div className="item">
        <img src="images/2019-president-circle.png" alt="2019 President Circle" />
      </div>
      <div className="item">
        <img src="images/fund-million.png" alt="fund million" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2013.png" alt="Fund pc 2013" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2014.png" alt="Fund pc 2014" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2015.png" alt="Fund pc 2015" />
      </div>
      <div className="item">
        <img src="images/fund-pc-2017.png" alt="fund pc 2017" />
      </div>
      <div className="item">
        <img src="images/fund-tc-2013.png" alt="fund tc 2013" />
      </div>
      <div className="item">
        <img src="images/Fund-tc-2014.png" alt="Fund tc 2014" />
      </div>
    </div>
  </section>
  <section className="p-0">
    <div className="col-md-11 m-auto col-12 bg-blue py-5 px-md-5 px-3 text-center case-eveluation">
      <div className="subheading fw-400 text-light mb-2"> ALWAYS COMPLIMENTARY, ABSOLUTELY CONFIDENTIAL. </div>
      <h3 className="h3 text-light mb-4 fw-600">CASE EVALUATION</h3>
      <div className="d-flex justify-content-center gap-4">
        <a className="btn btn-purple" href="#" title="Contact Info">Contact Info</a>
        <a className="btn btn-purple" href="tel:305-865-4311" title="Call Now"><i className="fas fa-phone-alt me-3" />Call Now</a>
      </div>
    </div>
  </section>
  <section className="text-center">
    <h3 className="h3 text-blue mb-4 mb-lg-5">CLIENT TESTIMONIALS</h3>
    <div className="testimonials-wrap col-lg-9 m-auto col-11">
      <div className="testimonials owl-carousel">
        <div className="item">
          <p> Mere words of sincere appreciation do not adequately express the relief you and your staff have provided in our unique situation. You have far exceeded our expectations. We will, without hesitation, recommend Green and Kahn with confidence, trust you
            in all our future business affairs. </p>
          <div className="client-name">Damon &amp; Kathleen</div>
        </div>
        <div className="item">
          <p> Now that the dust has settled I want to thank you both for making the process as do-able as possible at 3,000 miles. I was under great stress, fearing at any moment that the deal might
            fall through, but it gave me great comfort to have you both in my corner. Thank you again. </p>
          <div className="client-name">Bobby</div>
        </div>
        <div className="item">
          <p> I wanted to take a moment to say thanks again for doing what you do so well … Another satisfied customer. I appreciate all you do to get these deals to the closing table! </p>
          <div className="client-name">Judi</div>
        </div>
      </div>
    </div>
    <div className="more mt-4">
      <a className="more-btn" href="testimonials.html">Learn More</a>
    </div>
  </section>
  <section className="contact-sec">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light fw-700 mb-4">CONTACT US</h3>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact.html" title="Start Here">Start Here</a>
      </div>
    </div>
  </section>
    </>
    );
}
export default Home;