function CriminalDefenseVictimRepresentation(){
    return(
        <>
           <div>
  <section style={{background: 'url("images/criminal-header.png") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="inner-banner realestatelaw-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h1 className="h1 text-light mb-4">Criminal Defense &amp; Victim Representation</h1>
    </div>
  </section>
  <section className="text-center p-5 bg-purple">
    <div className="col-md-10 col-11 bg-purple m-auto">
      <p className="text-light m-0">Being charged with a crime, whether major or minor, is a serious matter the outcome of which can have lifelong consequences. On the other hand, as a victim of crime, the criminal justice system can seem overwhelming and confusing.</p>
    </div>
  </section>
  <section className="law-areas">
    <div className="content col-lg-10 col-11 m-auto text-center mb-5">
      <img src="images/criminal_defendant_icons.png" alt="real estate criminal defendant icons" />
      <h3 className="h3 m-4 text-blue fw-400">CRIMINAL DEFENDANT</h3>
      <p>A person facing criminal charges risks severe penalties and consequences, such as jail time, having a criminal record, and loss of relationships and future job prospects, among other things.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center my-5 py-5">
      <img src="images/criminal_victim_icons.png" alt="real estate criminal victim icons" />
      <h3 className="h3 m-4 text-blue fw-400">CRIMINAL VICTIM</h3>
      <p>A victim is defined as a person who has suffered physical or emotional harm, property damage, or economic loss as a result of a crime.</p>
    </div>
    <div className="content col-lg-10 col-11 m-auto text-center">
      <img src="images/criminal_closer_look_icons.png" alt="real estate criminal closer look icons" />
      <h3 className="h3 m-4 text-blue fw-400">CRIMINAL DEFENSE &amp; VICTIM REPRESENTATION CLOSER LOOK</h3>
      <p>A good criminal defense lawyer may help (1) reduce your criminal charge to a lesser offense (for example, reduce a felony to a misdemeanor); (2) lessen the severity of the punishment for the crime; (3) reduce or eliminate jail time (via probation, for example); and (4) help you develop a sound defense strategy.</p>
      <p>As a victim of crime, having experienced counsel on your side can be very empowering and greatly assist in navigating the complex criminal justice system. From being prepared for interviews with law enforcement to understanding the plea negotiation and trial process, an experienced counselor is extremely valuable.</p>
      <p>David Kahn Esq. started his career as a highly successful Assistant State Prosecutor. Let us use our experience to help you or your loved one who has been either charged with or has been the victim of a crime.</p>
    </div>
  </section>
  <section style={{background: 'url("images/footer_criminal.jpg") no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className="bottom-banner">
    <div className="col-md-10 col-11 m-auto p-5 text-center wraper">
      <h3 className="h3 text-light mb-4">UNCERTAIN WHERE TO BEGIN?</h3>
      <p className="text-white">SPEAK WITH AN EXPERIENCED KAHN &amp; KAHN ATTORNEY BEGIN HERE</p>
      <div className="d-flex justify-content-center">
        <a className="btn btn-purple" href="contact-us" title="Begin Here">Begin Here</a>
      </div>
    </div>
  </section>
</div>


        </>
    );
}
export default CriminalDefenseVictimRepresentation;